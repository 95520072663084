import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import MuseDentalServices from '../images/muse-dental-services.jpg';
import Bridges from '../images/services-bridges.png';
import Crowns from '../images/services-crowns.png';
import Invisalign from '../images/services-invisalign.jpeg';
import Fillings from '../images/services-fillings2.png';
import Whitening from '../images/services-teeth-whitening.png';
import RootCanal from '../images/services-root-canal.jpg';
import GeneralDentistry from '../images/services-general-dentistry.jpg';
import ChildrenDentistry from '../images/services-children-dentistry.jpg';
import NightGuard from '../images/services-night-guard.jpg';
import ToothExtraction from '../images/services-tooth-extraction.jpeg';
import AppointmentCTA from '../components/AppointmentCTA';
import SEO from '../components/Seo';

const Services = () => {

    return(
        <Layout>
            <SEO title="Muse Dental - Services" description="At Muse Dental, we make sure you're taken care of. We have comprehensive selection of services to make sure the entire family has a healthy smile."/>
            <header>
            <div className="relative bg-muse-yellow">
                <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-medium tracking-tight text-white sm:text-5xl lg:text-6xl text-center">Our Services</h1>
                </div>
                </div>
            </header>

            <section>
                <div className="relative bg-white py-16">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                        <div className="relative sm:py-16 lg:py-0">
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                <div className="relative pt-64 pb-10 rounded-md shadow-xl">
                                    <img className="absolute inset-0 h-100 w-full object-cover" src={MuseDentalServices} alt="Muse Dental Office"/>
                                </div>
                            </div>
                        </div>

                        <div className="relative mx-auto my-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            <div className="pt-12 sm:pt-16 lg:pt-20">
                                <h2 className="text-3xl text-muse-teal font-extrabold tracking-tight sm:text-4xl">
                                    Healthy Smiles All Around
                                </h2>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-xl">
                                    At Muse Dental, we make sure you're taken care of. We have comprehensive selection of services to make sure the entire family has a healthy smile.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           <section>
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                        <div className="max-w-7xl mx-auto">
                            <h2 className="text-4xl font-extrabold text-muse-teal">Services We Offer</h2>
                        </div>

                        <div className="mb-24 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                            <div className="mt-12 text-gray-900 space-y-6">
                                <p className="text-2xl font-bold">Hygiene and Periodontal Exam</p>
                                <p className="text-xl">
                                Continuing research shows a strong correlation between oral health and our overall systemic heath. There have been links established 
                                between poor oral hygiene and cardiovascular disease, diabetes, premature labour and low birth weight babies. Patients with these 
                                pre-existing systemic conditions are at greater risk for oral complications. 
                                </p>
                                <p className="text-xl">
                                At Muse Dental, we work with our patients to customise a hygiene 
                                protocol for each individual patient. We educate our patients on the importance of oral hygiene to preserve dentition and our overall health. 
                                </p>
                            </div>
                            <div className="pt-5">
                                <img className="h-100" src={GeneralDentistry} alt="General Dentistry"/>
                            </div>
                        </div>


                        <div className="mb-24 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                            <div className="mt-12 mb-12 text-gray-900 space-y-6">
                                <p className="text-2xl font-bold">Children's Dentistry</p>
                                <p className="text-xl">
                                We understand the importance of implementing positive dental experiences at an early age. Our office treats children of all ages using techniques 
                                that reinforce a positive experience. We offer routine exams, fillings and extractions for children of all ages. Children especially love watching 
                                their favourite Netflix show while we treat them.
                                </p>
                            </div>
                            <div className="pt-5">
                                <img className="h-100" src={ChildrenDentistry} alt="Children Dentistry"/>
                            </div>
                        </div>

                        <div className="mb-24 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                            <div className="mt-12 text-gray-900 space-y-6">
                                <p className="text-2xl font-bold">Teeth Whitening</p>
                                <p className="text-xl">
                                We have partnered with GLO whitening to provide our patients with the latest technology in teeth whitening. GLO is an innovative technology that uses 
                                special heat resistors and light reflectors inside the mouth piece that allow a gentle warming heat and safe LED light into the whitening gel, 
                                thereby speeding up the whitening process by energising the hydrogen peroxide formulation. This allows faster results without sensitivity. 
                                </p>
                            </div>
                            <div className="pt-5">
                                <img className="h-100" src={Whitening} alt="Whitening"/>
                            </div>
                        </div>

                        <div className="mb-24 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                            <div className="mt-12 text-gray-900 space-y-6">
                                <p className="text-2xl font-bold">Night Guards and Occlusal Splints</p>
                                <p className="text-xl">
                                These oral appliances can help with clenchers/grinders, as they will protect your teeth from chipping if you have a nighttime bruxing (grinding) habit. 
                                They can also be used in some cases to help reduce jaw pain, restore the correct position of the jaws before beginning a mouth makeover, or as the final 
                                step in a large case or mouth makeover to help prevent you from damaging your new investment.
                                </p>
                            </div>
                            <div className="pt-5">
                                <img className="h-100" src={NightGuard} alt="Night Guards"/>
                            </div>
                        </div>


                        <div className="mb-24 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                            <div className="mt-12 text-gray-900 space-y-6">
                                <p className="text-2xl font-bold">Fillings</p>
                                <p className="text-xl">
                                We offer composite resin restorations (tooth coloured fillings). We carefully match the composite to your natural teeth. These restorations require less 
                                removal of your natural tooth structure compared to silver amalgam restorations. 
                                </p>
                            </div>
                            <div className="pt-5">
                                <img className="h-100" src={Fillings} alt="Fillings"/>
                            </div>
                        </div>

                        <div className="mb-24 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                            <div className="mt-12 text-gray-900 space-y-6">
                                <p className="text-2xl font-bold">Root Canal Therapy</p>
                                <p className="text-xl">
                                Sometimes teeth with deep decay, trauma, large cracks, or abscesses end up needing root canal therapy in order to get you feeling comfortable. By removing the 
                                infected, inflamed, and painful internal nerve and replacing it with a root filling material and covering the tooth with a crown, we can get you out of pain 
                                while still saving your tooth. 
                                </p>
                            </div>
                            <div className="pt-5">
                                <img className="h-100" src={RootCanal} alt="Root Canal Therapy"/>
                            </div>
                        </div>

                        <div className="mb-24 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                            <div className="mt-12 text-gray-900 space-y-6">
                                <p className="text-2xl font-bold">Extractions</p>
                                <p className="text-xl">
                                Sometimes a tooth gets fractured, infected, or decayed so deep that it becomes unfixable. In these situations, we have plenty of experience in removing teeth 
                                quickly and comfortably in order to remove the problem. We will also discuss what your options are to replace the tooth so that you don’t end up with an 
                                unsightly gap in your smile, or a decreased ability to chew.
                                </p>
                            </div>
                            <div className="pt-5">
                                <img className="h-100" src={ToothExtraction} alt="Tooth Extraction"/>
                            </div>
                        </div>

                        <div className="mb-24 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                            <div className="mt-12 text-gray-900 space-y-6">
                                <p className="text-2xl font-bold">Invisalign</p>
                                <p className="text-xl">
                                Invisalign treatment is the process of wearing a series of clear, removable aligners that gradually straighten your teeth. No brackets and wires, and none 
                                of the restrictions that come with metal braces.
                                </p>
                                <p className="text-xl">
                                We create a unique, digital treatment plan that maps out the exact movements of your teeth. Your Invisalign clear aligners are then designed to apply the right amount of 
                                force to the right place at the right time based on your plan. We will be with you every step of the way to monitor your progress and ensure your aligners fit correctly.
                                </p>
                            </div>
                            <div className="pt-5">
                                <img className="h-100" src={Invisalign} alt="Invisalign"/>
                            </div>
                        </div>

                        <div className="mb-24 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                            <div className="mt-12 text-gray-900 space-y-6">
                                <p className="text-2xl font-bold">Bridges</p>
                                <p className="text-xl">
                                A bridge helps replace a missing tooth. The two teeth on either side of the missing-tooth-gap have a layer of tooth structure removed (just like with a crown), and then a porcelain 
                                bridge consisting of “two crowns connected by an artificial tooth” is made to fill the gap. 
                                </p>
                            </div>
                            <div className="pt-5">
                                <img className="h-100" src={Bridges} alt="Bridges"/>
                            </div>
                        </div>

                        <div className="mb-24 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                            <div className="mt-12 text-gray-900 space-y-6">
                                <p className="text-2xl font-bold">Crowns</p>
                                <p className="text-xl">
                                A crown, also called a cap, is a hollow, artificial tooth used to cover a damaged or decayed tooth. The crown restores the tooth and protects it from further damage. Crowns can 
                                also be used to cover a discoloured or misshapen tooth. A tooth that has been fixed with a crown looks and works very much like a natural tooth.
                                </p>
                            </div>
                            <div className="pt-5">
                                <img className="h-100" src={Crowns} alt="Crowns"/>
                            </div>
                        </div>

                       
                    </div>
                </div>
           </section>

          <AppointmentCTA/>
           
        </Layout>
    )
}

export default Services;